import BaseError from "./BaseError";
import { ErrorData } from "../models";

export default class WebError extends BaseError {
  status: number;
  code?: number;
  data?: ErrorData;

  constructor(status: number, code?: number, message?: string, data?: ErrorData) {
    super('Web Error', message ?? '');
    this.status = status;
    this.code = code;
    this.data = data;
  }
}
