import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import img from '../img/loader.gif';
import "../scss/loader.scss";
import logo from '../svg/logo.svg';

export class FallbackPreloader extends Component {
  render() {
    return (
      <>
        <div className="menu menu-primary">
          <div className="logo">
            <img src={logo} />
          </div>
        </div>
        <div className="menu-primary-clearfix" />
        <div className="page-loader">
          <img src={img} alt="Loading..." />
        </div>
      </>
    );
  }
}

type Props = WithTranslation & {
  networkError: boolean
};

class Preloader extends Component<Props> {

  /**
   * Renders network error message if needed.
   */
  renderNetworkError() {
    if (this.props.networkError === true) {
      return (
        <p>{this.props.t('components.Preloader.networkError')}</p>
      );
    }
  }

  render() {
    return (
      <div className="page-loader">
        <img src={img} alt={this.props.t('components.Preloader.loading')} />
        {this.renderNetworkError()}
      </div>
    );
  }
}

// Init i18n wrapper.
export default withTranslation()(Preloader);