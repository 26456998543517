import { lazy, useContext } from "react";
import { LoginContext } from "./contexts/login";
import "./scss/index.scss";
import { models } from "./services/login";

const GTApp = lazy(() => import("./GT/App"));
const DAApp = lazy(() => import('./DA/App'));
const HostessApp = lazy(() => import('./Hostess/App'));
const IndexOld = lazy(() => import('./legacyApp/index'));
const Login = lazy(() => import('./pages/Login'));

type Props = {};

export default function App(props: Props) {
  const login = useContext(LoginContext);

  const appVariant = function () {
    switch (login.role) {
      case models.Role.DarwinAgent:
        return <DAApp />
      case models.Role.ShopAssistantGT:
        return <GTApp />
      case models.Role.Hostess:
        return <HostessApp />
      default:
        return <IndexOld />
    }
  }

  return (
    <div>
      {login.authenticated ? appVariant() : <Login />}
    </div>
  );
}
