import CachedResponseNotFound from "./errors/CachedResponseNotFound";

type CachedResponseHeaders = {
  [header: string]: string
};

export default class CachedResponse<T> {
  protected _headers?: CachedResponseHeaders;
  protected _body?: T;
  private readonly url: string;
  private loaded: boolean;

  constructor(url: string) {
    this.url = url;
    this.loaded = false;
  }

  public save(headers: CachedResponseHeaders, body: T) {
    localStorage.setItem(this.url, JSON.stringify({
      headers: Object.fromEntries(Object.entries(headers).map(([header, value]) => [header.toLowerCase(), value])),
      body: body,
    }));
    this._headers = headers;
    this._body = body;
  }

  public load() {
    this.loaded = true;
    const cachedString = localStorage.getItem(this.url);
    if (cachedString === null) {
      return false;
    }
    const cachedObj = JSON.parse(cachedString);
    if (typeof cachedObj === 'object') {
      const properties = Object.getOwnPropertyNames(cachedObj);
      if (!properties.includes('headers') || !properties.includes('body')) {
        return false;
      }
    }
    else {
      return false;
    }
    this._headers = cachedObj.headers;
    this._body = cachedObj.body;
    return true;
  }

  public get exists() {
    if (!this.loaded) {
      this.load();
    }
    return this._headers !== undefined && this._body !== undefined;
  }

  public get headers() {
    if (this._headers === undefined) {
      throw new CachedResponseNotFound(this.url);
    }
    return this._headers;
  }

  public get body() {
    if (this._body === undefined) {
      throw new CachedResponseNotFound(this.url);
    }
    return this._body;
  }
}