import { PropsWithChildren, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginApiClient, models } from "../../services/login";
import Context, { initialState } from "./Context";

type Props = PropsWithChildren<{
  fallback: ReactChild | ReactFragment | ReactPortal,
}>;

export default function Provider(props: Props) {
  const [authenticated, setAuthenticated] = useState(initialState.authenticated);
  const [client] = useState(new LoginApiClient());
  const [name, setName] = useState(initialState.name);
  const [role, setRole] = useState(initialState.role);
  const [district, setDistrict] = useState(initialState.district);
  const [showOptIn, setShowOptIn] = useState(initialState.showOptIn);
  const [token, setToken] = useState(initialState.token);
  const [skipOtp, setSkipOtp] = useState(initialState.skipOtp);
  const [otp, setOtp] = useState(initialState.otp);
  const [canCreateGuestContact, setCanCreateGuestContact] = useState(initialState.canCreateGuestContact);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginByAuth = (auth: models.Auth) => {
    setName(auth.name);
    setRole(auth.role);
    setDistrict({
      id: auth.districtValue,
      name: auth.district,
    });
    setShowOptIn(auth.showOptIn);
    setToken(auth.token);
    setSkipOtp(auth.skipOtp);
    setOtp(auth.otp);
    setCanCreateGuestContact(auth.canCreateGuestContact);
    setAuthenticated(true);
  };

  const login = async (code: string) => {
    const auth = await client.login(code);
    localStorage.setItem('auth', JSON.stringify(auth));
    loginByAuth(auth);
  };

  const logout = () => {
    localStorage.clear();
    setAuthenticated(initialState.authenticated);
    setName(initialState.name);
    setRole(initialState.role);
    setDistrict(initialState.district);
    setShowOptIn(initialState.showOptIn);
    setToken(initialState.token);
    setSkipOtp(initialState.skipOtp);
    setOtp(initialState.otp);
    setCanCreateGuestContact(initialState.canCreateGuestContact);
    navigate('/');
  };

  useEffect(() => {
    (async () => {
      const auth = localStorage.getItem('auth');
      if (auth !== null) {
        const authObj = JSON.parse(auth) as models.Auth;
        if (Math.round(Date.now() / 1000) < authObj.expires) {
          loginByAuth(authObj);
        }
        else {
          logout();
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Context.Provider value={{
      login: login,
      logout: logout,
      authenticated: authenticated,
      name: name,
      role: role,
      district: district,
      showOptIn: showOptIn,
      token: token,
      skipOtp: skipOtp,
      otp: otp,
      canCreateGuestContact: canCreateGuestContact,
    }}>
      {loading ? props.fallback : props.children}
    </Context.Provider>
  );
}
