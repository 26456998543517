import { createContext } from "react";
import * as login from "../../services/login";

type Props = {
  login: (code: string) => Promise<void> | void,
  logout: () => void,
  authenticated: boolean,
  name: string,
  role: login.models.Role,
  district: {
    id: number,
    name: string,
  },
  showOptIn: boolean,
  token: string,
  skipOtp: boolean,
  otp: boolean,
  canCreateGuestContact: boolean,
};

export const initialState: Props = {
  login: () => { },
  logout: () => { },
  authenticated: false,
  name: '',
  role: login.models.Role.ShopAssistantLight,
  district: {
    id: 0,
    name: '',
  },
  showOptIn: false,
  token: '',
  skipOtp: false,
  otp: false,
  canCreateGuestContact: false,
};

export default createContext<Props>(initialState);
