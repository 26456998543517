/**
 * User's role.
 */
export enum Role {
  ShopAssistantLight = 0,
  ShopAssistantFull = 1,
  ShopAssistantGT = 2,
  BuisnessAdmin = 3,
  BuisnessBuilder = 4,
  CallCenter = 5,
  DarwinAgent = 6,
  Hostess = 7,
}

/**
 * Authorization information.
 */
export interface Auth {
  name: string,
  token: string,
  expires: number,
  role: Role,
  district: string,
  districtValue: number,
  showOptIn: boolean,
  skipOtp: boolean,
  otp: boolean,
  canCreateGuestContact: boolean,
}
